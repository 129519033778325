import React from "react"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"

/** color theme */
const colorPalette = theme.colorPalettes.pink

/** props */
interface Props {
  data: any
}

/** const */
const PageNotFound: React.FC<Props> = (props) => {
  return (
    <Layout title="Page Not Found | Norhart" colorPalette={colorPalette}>
      <HeroTopSection
        title="Oh No, Page Not Found!"
        subtitle="Looking for something, try these links"
        imageTitle="Page Not Found"
        image="/not-found/norhart-404-not-found-hero.png"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
export default PageNotFound
